import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getTransactionList = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-property-transactions/${body?.propertyId}?pageSize=${body?.pageSize}&page=${body?.page}`
    )
  );
  if (err) return err;
  return data;
};

const getTransactionDetailsData = async (transactionId, propertyId) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-property-transactions-details/transaction/${transactionId}/property/${propertyId}`
    )
  );
  if (err) return err;
  return data;
};

const getCompanyWithSearchableText = async (companyText) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-companies-search/${companyText}`)
  );
  if (err) return err;
  return data;
};

const updateTransactionDetailsData = async (
  param,
  transactionId,
  moreTransactonId
) => {
  try {
    const response = await client.patch(
      `/api/update-transaction-detail/transaction/${transactionId}/moreTransaction/${moreTransactonId}`,
      param
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const saveTransactionDetailsData = async (param, propertyId) => {
  try {
    const response = await client.post(
      `/api/create-transaction/property/${propertyId}`,
      param
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const getAllDocumentMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-document-master`)
  );
  if (err) return err;
  return data;
};

export {
  getTransactionList,
  getTransactionDetailsData,
  updateTransactionDetailsData,
  getCompanyWithSearchableText,
  saveTransactionDetailsData,
  getAllDocumentMaster,
};
