import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useNavigate } from "react-router";
import FilterIcon from "../../assets/filterIcon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesList,
  setCurrentPage,
  setPageSize,
  setCompanyId,
  getFilteredCompany,
  setCompanyInitialState,
  setDirectorsCnt,
} from "../../feature/slice/CompanyDetailsSlice";
import {
  setCurrentPage as _setDirectorsCurrentPage,
  setPageSize as _setDirectorsPageSize,
} from "../../feature/slice/DirectorDetailsSlice";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";

const Company = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, companyDetailsData, currentPage, pageSize, companiesCnt } =
    useSelector((store) => store.companyDetails);
  const { citiesData, companyFilters } = useSelector((store) => store.filters);

  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    if (
      companyFilters?.cin ||
      companyFilters?.companyName ||
      companyFilters?.companyType ||
      companyFilters?.cities?.length > 0
    ) {
      const citiesDataForFilter = [];
      for (let index = 0; index < companyFilters?.cities.length; index++) {
        citiesDataForFilter.push(
          citiesData?.[companyFilters?.cities?.[index]]?.title
        );
      }
      const body = {
        cin: companyFilters?.cin,
        companyName: companyFilters?.companyName,
        companyType: companyFilters?.companyType,
        city: citiesDataForFilter,
        currentPage: 1,
        pageSize: 10,
      };
      dispatch(getFilteredCompany(body));
    } else {
      const body = {
        pageSize: 10,
        page: 1,
      };
      dispatch(getCompaniesList(body));
    }
  }, []);

  const handleResetCompanySearch = () => {
    dispatch(
      setFilters({
        name: "localities",
        value: [],
      })
    );
    const buildingNameBody = {
      value: null,
      name: "buildingName",
    };
    dispatch(setFilters(buildingNameBody));

    const documentNoBody = {
      value: null,
      name: "documentNo",
    };
    dispatch(setFilters(documentNoBody));
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: "25%",
      editable: true,
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      width: "20%",
      editable: true,
    },
    {
      title: "Company Category",
      dataIndex: "companyCategory",
      width: "20%",
      editable: true,
    },
    {
      title: "City",
      dataIndex: "city",
      width: "15%",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "_id",
      width: "15%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link
              onClick={() => {
                handleResetCompanySearch();
                navigateTo(`/companyDetails/${record?._id}`);
                dispatch(setCompanyId(record?._id));
              }}
            >
              View
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                handleResetCompanySearch();
                dispatch(setCompanyId(record?._id));
                dispatch(setDirectorsCnt(record?.numberOfDirectors));
                dispatch(_setDirectorsCurrentPage(1));
                dispatch(_setDirectorsPageSize(10));
                navigateTo(`/directorsList/${record?._id}`);
              }}
            >
              Director
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    if (size === pageSize) {
      if (
        companyFilters?.cin ||
        companyFilters?.companyName ||
        companyFilters?.companyType ||
        companyFilters?.cities?.length > 0
      ) {
        const citiesDataForFilter = [];
        for (let index = 0; index < companyFilters?.cities.length; index++) {
          citiesDataForFilter.push(
            citiesData?.[companyFilters?.cities?.[index]]?.title
          );
        }
        const body = {
          cin: companyFilters?.cin,
          companyName: companyFilters?.companyName,
          companyType: companyFilters?.companyType,
          city: citiesDataForFilter,
          currentPage: page,
          pageSize: pageSize,
        };
        dispatch(getFilteredCompany(body));
      } else {
        const body = {
          pageSize: pageSize,
          page,
        };
        dispatch(getCompaniesList(body));
      }
    } else dispatch(setCurrentPage(1));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setCurrentPage(1));
    if (
      companyFilters?.cin ||
      companyFilters?.companyName ||
      companyFilters?.companyType ||
      companyFilters?.cities?.length > 0
    ) {
      const citiesDataForFilter = [];
      for (let index = 0; index < companyFilters?.cities.length; index++) {
        citiesDataForFilter.push(
          citiesData?.[companyFilters?.cities?.[index]]?.title
        );
      }
      const body = {
        cin: companyFilters?.cin,
        companyName: companyFilters?.companyName,
        companyType: companyFilters?.companyType,
        city: citiesDataForFilter,
        currentPage: 1,
        pageSize: pageSize,
      };
      dispatch(getFilteredCompany(body));
    } else {
      const body = {
        pageSize: pageSize,
        page: 1,
      };
      dispatch(getCompaniesList(body));
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "20px",
          }}
        >
          <div
            className="m-4"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {!props.filterCardVisible && (
              <Button
                onClick={props.toggleFilterCard}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "18px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    style={{ width: "24px" }}
                    src={FilterIcon}
                    alt="Filter"
                  />
                  Filter
                </div>
              </Button>
            )}
            <h1>Company List</h1>
          </div>
          <div className="list-options m-4">
            <Button
              className="mr-4"
              onClick={() => {
                handleResetCompanySearch();
                navigateTo(`/companyDetails`);
                dispatch(setCompanyId(null));
                dispatch(setCompanyInitialState());
              }}
            >
              Add Company
            </Button>
            <ReloadOutlined
              style={{ cursor: "pointer" }}
              title="Reload Company List"
              onClick={() => {
                const body = {
                  pageSize: 10,
                  page: 1,
                };
                dispatch(getCompaniesList(body));
                dispatch(setPageSize(10));
                dispatch(setCurrentPage(1));
              }}
            />
          </div>
        </div>
        <Table
          className="w-100 m-4 mt-0"
          bordered
          dataSource={companyDetailsData}
          columns={columns}
          size="small"
          loading={loading}
          pagination={false}
          rowKey={"_id"}
        />
        {companyDetailsData?.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={(currPage, pageSize) =>
              handlePaginationChange(currPage, pageSize)
            }
            total={companiesCnt}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default Company;
