import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getPropertiesList as _getPropertiesList,
  getFilteredProperty as _getFilteredProperty,
  getPropertyDetailsData as _getPropertyDetailsData,
  updatePropertyDetailsData as _updatePropertyDetailsData,
  savePropertyDetailsData as _savePropertyDetailsData,
  getAllDistinctStateMaster as _getAllDistinctStateMaster,
  getAllDistinctCityByState as _getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity as _getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket as _getAllDistinctLocalityByCityAndMicromarket,
} from "../services/PropertyDetailsAPI";
import { get } from "lodash";
import { FormatPropertyDetails } from "../../utils/formatSearchData/FormatPropertyDetails";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  propertyDetailsData: [],
  isBuildingInfoEditable: true,
  currentPage: 1,
  pageSize: 10,
  propertyDetails: {},
  propertyId: null,
  transactionsCnt: 0,
  stateMaster: [],
  cityMaster: [],
  microMarketMaster: [],
  localityMaster: [],
  propertiesCnt: 0,
};

const propertyDetailsSlice = createSliceWithThunks({
  name: "propertyDetails",
  initialState,
  reducers: (create) => ({
    setBuildingInfoEditable: (state, action) => {
      state.isBuildingInfoEditable = action?.payload;
    },
    getPropertiesList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getPropertiesList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.propertyDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.propertyDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.propertyDetailsData = action?.payload?.data?.data;
          state.propertiesCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setBuildingsInfo: (state, action) => {
      state.propertyDetails = {
        ...state.propertyDetails,
        [action?.payload?.name]: action?.payload?.value,
      };
    },
    updateBuildingsInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const propertyId = thunkAPI.getState().propertyDetails?.propertyId;
          let data = await _updatePropertyDetailsData(_, propertyId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    saveBuildingsInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _savePropertyDetailsData(_);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.propertyId = action?.payload?.data?.property?._id;
        },
      }
    ),
    getFilteredProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getFilteredProperty(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.propertyDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.propertyDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.propertyDetailsData = action?.payload?.data?.data;
          state.propertiesCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },
    getPropertyDetailsData: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getPropertyDetailsData(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.propertyDetails = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.propertyDetails = {};
        },
        fulfilled: (state, action) => {
          state.propertyDetails = FormatPropertyDetails(
            action?.payload?.data?.[0]
          );
          state.loading = false;
        },
      }
    ),
    setPropertyId: (state, action) => {
      state.propertyId = action?.payload;
    },
    setPropertyInitialState: (state, action) => {
      state.loading = false;
      state.propertyDetailsData = [];
      state.isBuildingInfoEditable = true;
      state.currentPage = 1;
      state.pageSize = 10;
      state.propertyDetails = {};
      state.propertyId = null;
      state.stateMaster = [];
      state.cityMaster = [];
      state.microMarketMaster = [];
      state.localityMaster = [];
    },
    setTransactionCnt: (state, action) => {
      state.transactionsCnt = action?.payload;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    getAllDistinctStateMaster: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDistinctStateMaster();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.stateMaster = [];
        },
        rejected: (state, action) => {
          state.stateMaster = [];
        },
        fulfilled: (state, action) => {
          const stateMasterArray = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            stateMasterArray.push(element);
          }
          state.stateMaster = stateMasterArray;
        },
      }
    ),
    getAllDistinctCityByState: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const state =
            thunkAPI.getState().propertyDetails?.propertyDetails?.state;
          let data = await _getAllDistinctCityByState(state);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.cityMaster = [];
        },
        rejected: (state, action) => {
          state.cityMaster = [];
        },
        fulfilled: (state, action) => {
          const cityMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            cityMasterArray.push(element);
          }
          state.cityMaster = cityMasterArray;
        },
      }
    ),
    getAllDistinctMicroMarketByCity: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const city =
            thunkAPI.getState().propertyDetails?.propertyDetails?.city;
          let data = await _getAllDistinctMicroMarketByCity(city);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.microMarketMaster = [];
        },
        rejected: (state, action) => {
          state.microMarketMaster = [];
        },
        fulfilled: (state, action) => {
          const microMarketMasterArray = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            microMarketMasterArray.push(element);
          }
          state.microMarketMaster =
            action?.payload?.data?.length === 1 &&
            action?.payload?.data[0] === ""
              ? []
              : microMarketMasterArray;
        },
      }
    ),
    getAllDistinctLocalityByCityAndMicromarket: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const city =
            thunkAPI.getState().propertyDetails?.propertyDetails?.city;
          const microMarket =
            thunkAPI.getState().propertyDetails?.propertyDetails?.microMarket;
          let data = await _getAllDistinctLocalityByCityAndMicromarket(
            city,
            microMarket
          );
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.localityMaster = [];
        },
        rejected: (state, action) => {
          state.localityMaster = [];
        },
        fulfilled: (state, action) => {
          const localityMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            localityMasterArray.push(element);
          }
          state.localityMaster =
            action?.payload?.data?.length === 1 &&
            action?.payload?.data[0] === ""
              ? []
              : localityMasterArray;
        },
      }
    ),
  }),
});

export const {
  getPropertiesList,
  setBuildingInfoEditable,
  setBuildingsInfo,
  updateBuildingsInfo,
  getFilteredProperty,
  setCurrentPage,
  setPageSize,
  getPropertyDetailsData,
  setPropertyId,
  setPropertyInitialState,
  setTransactionCnt,
  saveBuildingsInfo,
  setLoading,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket,
} = propertyDetailsSlice.actions;
export default propertyDetailsSlice.reducer;
