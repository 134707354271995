import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useNavigate } from "react-router";
import FilterIcon from "../../assets/filterIcon.png";
import "./Property.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertiesList,
  setCurrentPage,
  setPageSize,
  setPropertyId,
  getFilteredProperty,
  setPropertyInitialState,
  setTransactionCnt,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  setCurrentPage as _setTransactionsCurrentPage,
  setPageSize as _setTransactionsPageSize,
} from "../../feature/slice/TransactionDetailsSlice";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";

const Property = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, propertyDetailsData, currentPage, pageSize, propertiesCnt } =
    useSelector((store) => store.propertyDetails);
  const { localitiesData, propertyFilters } = useSelector(
    (store) => store.filters
  );

  const columns = [
    {
      title: "Property Name",
      dataIndex: "propertyName",
      width: "15%",
      editable: true,
    },
    {
      title: "Locality",
      dataIndex: "locality",
      width: "25%",
      editable: true,
    },
    {
      title: "Property Type",
      dataIndex: "propertyType",
      width: "20%",
      editable: true,
    },
    {
      title: "No of Transactions",
      dataIndex: "numberOfTransaction",
      width: "15%",
      editable: true,
    },
    {
      title: "City",
      dataIndex: "city",
      width: "10%",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "_id",
      width: "25%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link
              onClick={() => {
                handleResetPropertySearch();
                navigateTo(`/propertyDetails/${record?._id}`);
                dispatch(setPropertyId(record?._id));
              }}
            >
              View
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                handleResetPropertySearch();
                dispatch(setPropertyId(record?._id));
                dispatch(setTransactionCnt(record?.numberOfTransaction));
                dispatch(_setTransactionsCurrentPage(1));
                dispatch(_setTransactionsPageSize(10));
                navigateTo(`/transactionsList/${record?._id}`);
              }}
            >
              Transaction
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    if (
      propertyFilters?.documentNo ||
      propertyFilters?.buildingName ||
      propertyFilters?.localities?.length > 0
    ) {
      const localitiesDataForFilter = [];
      for (let index = 0; index < propertyFilters?.localities.length; index++) {
        localitiesDataForFilter.push(
          localitiesData?.[propertyFilters?.localities?.[index]]?.title
        );
      }
      const body = {
        documentNumber: propertyFilters?.documentNo,
        buildingName: propertyFilters?.buildingName,
        localities: localitiesDataForFilter,
        currentPage: 1,
        pageSize: 10,
      };
      dispatch(getFilteredProperty(body));
    } else {
      const body = {
        pageSize: 10,
        page: 1,
      };
      dispatch(getPropertiesList(body));
    }
  }, []);

  const handleResetPropertySearch = () => {
    dispatch(
      setFilters({
        name: "localities",
        value: [],
      })
    );
    const buildingNameBody = {
      value: null,
      name: "buildingName",
    };
    dispatch(setFilters(buildingNameBody));

    const documentNoBody = {
      value: null,
      name: "documentNo",
    };
    dispatch(setFilters(documentNoBody));
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    if (size === pageSize) {
      if (
        propertyFilters?.documentNo ||
        propertyFilters?.buildingName ||
        propertyFilters?.localities?.length > 0
      ) {
        const localitiesDataForFilter = [];
        for (
          let index = 0;
          index < propertyFilters?.localities.length;
          index++
        ) {
          localitiesDataForFilter.push(
            localitiesData?.[propertyFilters?.localities?.[index]]?.title
          );
        }
        const body = {
          documentNumber: propertyFilters?.documentNo,
          buildingName: propertyFilters?.buildingName,
          localities: localitiesDataForFilter,
          currentPage: page,
          pageSize: pageSize,
        };
        dispatch(getFilteredProperty(body));
      } else {
        const body = {
          pageSize: pageSize,
          page,
        };
        dispatch(getPropertiesList(body));
      }
    } else dispatch(setCurrentPage(1));
  };

  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));
    if (
      propertyFilters?.documentNo ||
      propertyFilters?.buildingName ||
      propertyFilters?.localities?.length > 0
    ) {
      const localitiesDataForFilter = [];
      for (let index = 0; index < propertyFilters?.localities.length; index++) {
        localitiesDataForFilter.push(
          localitiesData?.[propertyFilters?.localities?.[index]]?.title
        );
      }
      const body = {
        documentNumber: propertyFilters?.documentNo,
        buildingName: propertyFilters?.buildingName,
        localities: localitiesDataForFilter,
        currentPage: 1,
        pageSize: size,
      };
      dispatch(getFilteredProperty(body));
    } else {
      const body = {
        pageSize: size,
        page: 1,
      };
      dispatch(getPropertiesList(body));
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "20px",
          }}
        >
          <div className="m-4 align-center property">
            {!props.filterCardVisible && (
              <Button
                onClick={props.toggleFilterCard}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "18px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    style={{ width: "24px" }}
                    src={FilterIcon}
                    alt="Filter"
                  />
                  Filter
                </div>
              </Button>
            )}
            <h1>Property List</h1>
          </div>
          <div className="list-options m-4">
            <Button
              className="mr-4"
              onClick={() => {
                handleResetPropertySearch();
                navigateTo(`/propertyDetails`);
                dispatch(setPropertyId(null));
                dispatch(setPropertyInitialState());
              }}
            >
              Add Property
            </Button>
            <ReloadOutlined
              style={{ cursor: "pointer" }}
              title="Reload Property List"
              onClick={() => {
                const body = {
                  pageSize: 10,
                  page: 1,
                };
                dispatch(getPropertiesList(body));
                dispatch(setPageSize(10));
                dispatch(setCurrentPage(1));
              }}
            />
          </div>
        </div>
        <Table
          className="w-100 m-4 mt-0"
          bordered
          dataSource={propertyDetailsData}
          columns={columns}
          size="small"
          loading={loading}
          pagination={false}
          rowKey={"_id"}
        />
        {propertyDetailsData?.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={(currPage, pageSize) =>
              handlePaginationChange(currPage, pageSize)
            }
            total={propertiesCnt}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default Property;
